<template>
	<div id="adoptOrderDetail">
		<page-order-detail :is-shop="0"></page-order-detail>
	</div>
</template>

<script>
	import pageOrderDetail from '@/components/layout/adopt/page-order-detail.vue'
	export default{
		components:{
			pageOrderDetail
		},
		beforeRouteLeave (to, from, next) {
			to.meta.keepAlive = to.path == '/adopt/orderList' ? true :false
			next();
		},
	}
</script>

<style lang="scss">

</style>
